import './banner-estatisticas.css';
export default function BannerEstatisticas(){
    return(
    <section class="estat sec-featured banner-featured">
        <div class="container">
            <div class="row justify-content-between al-center">
                <div class="box-title col-auto">
                    <h1>
                        <img src="https://resources-palpiteepropaganda.promosatelie.com.br/images/frontend/titulos/brilhar.png" alt="Jogue Agora" class="title-front jogue-agora-img"/>
                    </h1>
                </div>
                <div class="box-character col-auto"></div>
                <div class="box-game col-auto boa-sorte">
                    <div class="btn-game-style">Boa<span>Sorte</span></div>
                </div>
            </div>
        </div>
    </section>
    );
}