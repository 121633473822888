import './banner-jogar.css'
import tituloBanner from '../../assets/images/banner/home-titulo.png'
function BannerJogar(){
    return(
       
        <section className="sec-featured banner-featured-jogar banner-featured purple">
            <div className="container jogar-container">
                {/* <div className="side_one box-title">
                    <h1>
                        <img src={tituloBanner} alt="" className='title-front img-home-titulo' />
                    </h1>
                </div> */}
                <div class="box-title col-auto text-banner-jogar">
                    <h1>
                        <img src="https://resources-palpiteepropaganda.promosatelie.com.br/images/frontend/titulos/seus-pontos-titulo.png" alt="Veja meus pontos" class="title-front" />
                        <br />
                        <img src="https://resources-palpiteepropaganda.promosatelie.com.br/images/frontend/titulos/seus-pontos-titulo-pontos.png" alt="Pontos" class="title-back"/>
                    </h1>
                </div>
                <div className="side_two-jogar"></div>
                <div class="box-game col-auto">
                    <div class="btn-game-style">Analise seu<span>Desempenho</span></div>
                </div>
            </div>
        </section>
      
    )
}

export default BannerJogar;