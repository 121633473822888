import './banner-home.css'
import tituloBanner from '../../assets/images/banner/home-titulo.png'
function BannerHome(){
    return(
       
        <section className="sec-featured banner-featured">
            <div className="container">
                <div className="side_one box-title">
                    <h1>
                        <img src={tituloBanner} alt="" className='title-front img-home-titulo' />
                    </h1>
                </div>
                <div className="side_two"></div>
                <div className="side_three"></div>
            </div>
            <div className="container">
            <div className="text-ext">
                <div><span class="text-contact  d-none d-lg-block">Em caso de dúvidas, escreva para <br /> <b>atendimento@palpiteepropaganda.com.br</b></span></div>
                <div><span class="text-small-banner  d-none d-lg-block">Campanha válida de 21/11/2022 a 18/12/2022. <br /> Imagens meramente ilustrativas.</span></div>
            </div>
            </div>
        </section>
      
    )
}

export default BannerHome;