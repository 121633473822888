import api from '../../services/api'
import {useForm} from 'react-hook-form'
import { useState } from 'react';
import Swal from 'sweetalert2'

export default function ModalRecoverPasswordBody({closeModalRecoverPasswordBody}){
    const {register, handleSubmit, formState,  formState:{errors, isValid}} = useForm({mode:"all", reValidateMode:"onBlur"});
    const [email, setEmail] = useState('');
    const [isSending, setIsSending] = useState(false);

    const handleRecoverPassword = () => {
        handleSubmit(async(e)=>{
            setIsSending(true);
            try{
                localStorage.setItem('email_bolao', email);
                const response = await api.post(`/api/v1/auth/recovery_password`, {
                    email
                })
                console.log('success');
                Swal.fire({
                    icon: 'success',
                    title: 'Recuperação de senha realizada com sucesso!',
                    text:  `Aguarde o envio de um email com as instruções para recuperação de senha.`,
                  })
            }catch(err){
                Swal.fire({
                    icon: 'error',
                    title: err.response.data.title,
                    text:  err.response.data.message,
                  })
                    setIsSending(false);
                  
                  return
                // console.log(err.response.data.error.password[0])
                // console.log(err.response.data.error.taxid[0])
            }
            setIsSending(false);
        
            setEmail('');

        })()
    }

    return(
        <div className="content-recover-password">
            <form onSubmit={handleSubmit(handleRecoverPassword)}>
                <div className="header-register">
                        <h5>ESQUECEU A SENHA?</h5>
                </div>
                <div className="body-register">
                <h4>Preencha o seu e-mail abaixo</h4>
                <div class="form-group col-12">
                    <label for="inputMail1">E-mail</label>
                    <input
                    {...register("email", {
                        required: "Email Obrigatório",
                        pattern: {
                            value: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                            message: "Email inválido!"
                            }
                        })}
                    type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} class="form-control" />
                    {errors.email && <span  className="msgs-error-validate">{errors.email.message}</span> }
                </div>
                </div>
                <div className="footer-register">
                <button type="button" class="btn-std" data-dismiss="modal" onClick={closeModalRecoverPasswordBody} >Fechar</button>
                <button type="submit" class="btn-std purple">
                {
                    isSending ?
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    :
                    <span>ENVIAR</span>

                }
                </button>
                <input type="hidden" name="table" value="1"></input>
            </div>     
            </form>
    </div>
    )
}