export default function TabelaSingle({data, grupo, titulo }){
    return(
        <div className="single">
            <div className='gruoup_box_title'>
            <h3 style={{textAlign:'left'}}>{titulo}</h3>

        </div>
        <div class="content-tables row">
            <div class="box-ranking nopad col-6 col-sm-4 col-xl-7">
                <table class="table">
                    <colgroup>
                        <col class="tabela-times-col-posicao"></col>
                        <col class="tabela-times-col-time"></col>
                        <col class="tabela-times-col-variacao"></col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th colspan="3" className='classificacao'>Classificação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.filter(
                                groupItem => groupItem.group === grupo
                            ).sort(
                               //filter order By pontos
                               (a, b) => (a.pontos < b.pontos) ? 1 : -1

                            ).map((item, index) => {
                                
                                    return (
                                        <tr>
                                            <td style={{width:40}}>{index + 1}</td>
                                            <td style={{width:40}}>
                                                <img src={item.logo} style={{
                                                    width: '20px',
                                                }} />
                                            </td>
                                            <td>{item.name}</td>

                                        </tr>

                                    )
                            
                             })
                        }
                    </tbody>
                </table>
            </div>
            <div class="box-points nopad col-6 col-sm-8 col-xl-5">
                <table class="table classificacao">
                    <thead>
                        <tr>
                            <th>P</th>
                            <th>J</th>
                            <th>V</th>
                            <th>E</th>
                            <th>D</th>
                            <th>GP</th>
                            <th>GC</th>
                            <th>SG</th>
                           
                            <th>ÚLT.JOGOS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.filter(
                                groupItem => groupItem.group === grupo
                            ).sort(
                                //filter order By pontos
                                (a, b) => (a.pontos < b.pontos) ? 1 : -1

                             ).map((item, index) => {
                                
                                    return (
                                        <tr>
                                        <td>{item.pontos}</td>
                                        <td>{item.jogos}</td>
                                        <td>{item['vitórias']}</td>
                                        <td>{item.empates}</td>
                                        <td>{item.derrotas}</td>
                                        <td>{item.gols}</td>
                                        <td>{item.gols_sofridos}</td>
                                        <td>{item.saldo}</td>
                                        
                                        <td class="tabela-pontos-ultimos-jogos">
                                            {/* {item['últimas_partidas'][0] === 'win' ? <span class="tabela-icone-ult tabela-icone-circulo tabela-icone-v"></span> : ''}
                                            {item['últimas_partidas'][1] === 'lost' ? <span class="tabela-icone-ult tabela-icone-circulo tabela-icone-d"></span> : ''}
                                            {item['últimas_partidas'][2] === 'draw' ? <span class="tabela-icone-ult tabela-icone-circulo tabela-icone-e"></span>: ''} */}
                                            {
                                                item['últimas_partidas'].map((item, index) => {
                                                    if(item === 'win'){
                                                        return <span class="tabela-icone-ult tabela-icone-circulo tabela-icone-v"></span>
                                                    }else if(item === 'lost'){
                                                        return <span class="tabela-icone-ult tabela-icone-circulo tabela-icone-d"></span>
                                                    }else if(item === 'draw'){
                                                        return <span class="tabela-icone-ult tabela-icone-circulo tabela-icone-e"></span>
                                                    }
                                                })
                                            }
                                           
                                            {/* <span class="tabela-icone-ult tabela-icone-circulo tabela-icone-e"></span>
                                            <span class="tabela-icone-ult tabela-icone-circulo tabela-icone-d"></span> */}
                                        </td>
                                    </tr>

                                    )
                            
                             })
                        }

                    </tbody>
                </table>
            </div>

        </div>
    </div>
    )
}