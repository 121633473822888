import './password-recovery.css';
import api from '../../services/api'
import {useForm} from 'react-hook-form'
import { useState } from 'react';
import Swal from 'sweetalert2'
import LogoPalpite from '../../assets/images/logos/logo-amarelo2.png';
export default function RecoveryPassword(props){
    const {register, handleSubmit, watch,  formState:{errors, isValid}} = useForm({mode:"all", reValidateMode:"onBlur"});
    const [password, setPassword] = useState('');
    const [password_confirmation, setPasswordConfirmation] = useState('');
    const [isSendingPassword, setIsSendingPassword] = useState(false);


    const handleRecoverPassword = () => {
        handleSubmit(async(e)=>{
            setIsSendingPassword(true);

            try{
                    
                const response = await api.post(`/api/v1/auth/reset_password`, {
                    token: props.match.params.id,
                    email: localStorage.getItem('email_bolao'),
                    password,
                    password_confirmation,
                })
                console.log('success');
                Swal.fire({
                    icon: 'success',
                    title: 'Recuperação de senha realizada com sucesso!',
                    text:  `Faça seu login novamente.`,
                }).then(function() {
                    window.location = "/";
                });
            }catch(err){
                console.log(err.response.data.error)
                if(err.response.status === 401){
                    Swal.fire({
                        icon: 'error',
                        title:err.response.data.title,
                        text: err.response.data.message,
                     }).then(function() {
                        window.location = "/";
                        });
                        setIsSendingPassword(false);
                      
                      return
                }
                else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro',
                        html:
                        `${err.response.data.error.token ? err.response.data.error.token[0] : '' } ${err.response.data.error.password_confirmation ? err.response.data.error.password_confirmation[0] : ''} ${err.response.data.error.password ? err.response.data.error.password[0] : ''} ${err.response.data.error.email ? err.response.data.error.email[0] : ''}`,
                      }).then(function() {
                        window.location = "/";
                        });
                        setIsSendingPassword(false);
                      
                      return
                }
                // console.log(err.response.data.error.password[0])
                // console.log(err.response.data.error.taxid[0])
            }
            setIsSendingPassword(false);
        
            setPassword('');
            setPasswordConfirmation('');

        })()
    }

    return(
        <div className="recovery-full">
            <main className='recovery-logo-main'>
                <h1>
                    <img src={LogoPalpite} alt="Palpite Propaganda" />
                </h1>
            </main>
            <div className="recovery-box">
                <div className="recovery-box-title">
                    <h2>Crie uma nova senha </h2>
                </div>
                <div className="recovery-box-form">
                    <form  onSubmit={handleSubmit(handleRecoverPassword)}>
                        <div className="recovery-box-form-input">
                            <div className='box-inputs-recovery'>
                                <label>Nova senha</label>
                                <input 
                                {...register("password", {
                                    required: "Senha Obrigatória",
                                    minLength:{value:6, message:"Senha curta demais"},
                                    maxLength:{value:16, message:"Senha grande demais"}
                                    })}
                                    value={password} onChange={(e) => setPassword(e.target.value)}  className='form-control' type="password" name="password" id="password" />
                                {errors.password && <span  className="msgs-error-validate">{errors.password.message}</span> }
                            </div>
                            <div className='box-inputs-recovery'> 
                                <label>Confirmar nova senha</label>
                                <input 
                                {...register("password_confirmation", {
                                    required: "Senha Obrigatória",
                                    validate: (value) => value === watch('password') || "Ops! As senhas informadas estão diferentes." 
                                    })}
                                    type="password" id="password_confirmation" name="password_confirmation" value={password_confirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} class="form-control" data-required="true" data-msg="Preencha sua senha novamente" data-repeat="senha" />
                                    {errors.password_confirmation && <span className="msgs-error-validate">{errors.password_confirmation.message}</span> }
                            </div>
                            <div className="btn-recovery">
                                <button type="submit" name="save" class="btn-std">
                                {
                                    isSendingPassword ?
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    :
                                    <span>CONFIRMAR</span>

                                }
                                </button>
                            </div>
                        </div>  
                    </form>
                </div>
        </div>
        </div>
    )
}