import api from '../../services/api'
import './jogos.css'
export default function Jogos({data, fase, titulo, descricao}){
    async function hint(id, home_team, away_team){

        if(!home_team){
            //create red border
            document.getElementById(`home_${id}`).style.borderColor = 'red';
        }else{
            //create green border
            document.getElementById(`home_${id}`).style.borderColor = 'green';
        }

        if(!away_team){
            //create red border
            document.getElementById(`away_${id}`).style.borderColor = 'red';
        }else{
            //create green border
            document.getElementById(`away_${id}`).style.borderColor = 'green';
        }

        if(home_team && away_team){

            try{
                
                // setHomeHint(homeHint)
                // setAwayHint(awayHint)
                const response = await api.post(`/api/v1/matches/hint`, {
                    id,
                    home_team,
                    away_team
                })
            // console.log("home", homeHint)
            // console.log("away", awayHint)
            // console.log("id", id)
            
        }catch(e){
            console.log(e)
        }
        }
        
        
    

}
    return(
        <>
{
    data.filter(
        match => match.data?.group?.group_name === fase
    ).map((match, index)=> {
        return(

<div class="row row_jogos">
        {index === 0 && 
        <div className='gruoup_box_title'>
            <h3 style={{textAlign:'left'}}>{titulo}</h3>
            {/* <div className="fase-titulo">
            <span class="tabela-navegacao-seletor">{descricao}</span>
            </div> */}
        </div>
        }
    <div class="col-12 col-md-5 my-game">
        {index === 0 && <div class="title my-game"><h4>Seus Jogos</h4></div>}
        
        <div class="single-game lista-de-jogos-item">
        <div class="infos placar-jogo-informacoes">
            <p><strong>{new Date(match.match_start_fuso).toLocaleDateString(
                'pt-BR',
            )} {new Date(match.match_start_fuso).toLocaleTimeString(
                'pt-BR',
            )}</strong> - {match.data?.venue?.city ? match.data?.venue?.city : 'A definir' }</p>
            </div>
            <div class="times">
                <div className={`row justify-content-center align-items-center ${match.disable_hint === false ? 'hv_tooltip': ''}`}>
                    <div class="mandante single-time col-auto">
                        <h5 class="initials">{match.data?.home_team?.short_code ? match.data?.home_team?.short_code : '???' }</h5>
                        <img src={match.data?.home_team?.logo} alt={match.data?.home_team?.name} />
                            <input 
                            disabled={!match.disable_hint}
                            id={`home_${match.id}`}
                            type="text" 
                            class="field-goal" 
                            defaultValue={
                                match.hint?.home_team
                            }
                           
                            onChange={(e)=> hint(match.id, e.target.value, document.getElementById(`away_${match.id}`).value ? document.getElementById(`away_${match.id}`).value : 0)
                                
                            }
                             />
                            </div>
                            <div class="versus col-auto">
                                <span>X</span>
                            </div>
                            <div class="visitante single-time col-auto">
                                <input 
                                disabled={!match.disable_hint}
                                id={`away_${match.id}`}
                                type="text" 
                                class="field-goal" 
                                defaultValue={
                                    match.hint?.away_team
                                }
                                
                                onChange={(x)=> hint(match.id, document.getElementById(`home_${match.id}`).value ? document.getElementById(`home_${match.id}`).value : 0, x.target.value)
                                }  
                                />
                                <img src={match.data?.away_team?.logo} alt={match.data?.away_team?.name} />
                                    <h5 class="initials">{match.data?.away_team?.short_code ? match.data?.away_team?.short_code : '???'}</h5>
                            </div>
                    </div>
                </div>
        </div>
    </div>
    <div class="col-12 col-md-5 official-score ">
    {index === 0 && <div class="title my-game"><h4>Placar oficial</h4></div>}
    
        <div class="single-game lista-de-jogos-item">
            <div class="infos placar-jogo-informacoes">
            <p><strong>{new Date(match.match_start_fuso).toLocaleDateString(
                'pt-BR',
            )} {new Date(match.match_start_fuso).toLocaleTimeString(
                'pt-BR',
            )}</strong> - {match.data?.venue?.city ? match.data?.venue?.city : 'A definir' }</p>
            </div>
            <div class="times">
                <div class="row justify-content-center align-items-center">
                    <div class="mandante single-time col-auto">
                        <h5 class="initials">{match.data?.home_team?.short_code ? match.data?.home_team?.short_code : '???'}</h5>
                        <img src={match.data?.home_team?.logo} alt={match.data?.home_team?.name} />
                            <input type="text" class="field-goal" value={match.data?.stat.home_score} disabled="" />
                            </div>
                            <div class="versus col-auto">
                                <span>X</span>
                            </div>
                            <div class="visitante single-time col-auto">
                                <input type="text" class="field-goal" value={match.data?.stat.away_score} disabled="" />
                                <img src={match.data?.away_team?.logo} alt={match.data?.away_team?.name} />
                                    <h5 class="initials">{match.data?.away_team?.short_code ? match.data?.away_team?.short_code : '???'}</h5>
                            </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-2 points">
        {index === 0 && <div class="title my-game"><h4>Pontos</h4></div>}
            <div class="single-game lista-de-jogos-item">
                <p>{match.points?.total == null ? '-' : match.points?.total}</p>
                {match.points?.total == null ? '': (<>
                <div className='tooltip_points'>
                    {match.points?.message}
                </div>
                </>)}
            </div>
        </div>
    </div>
        )
    })
}
        </>
    )
}