import './banner-ranking.css';
import Busque from '../../assets/images/banner/busque.png'
import oTopo from '../../assets/images/banner/otopo.png'
import busqueOtopo from '../../assets/images/banner/busque_o_topo.png'
export default function BannerRanking(){
    return(
<section class="sec-featured banner-featured">
        <div class="container">
            <div class="row justify-content-between">
                <div class="box-title col-auto">
                    <h1 class="d-none d-md-block">
                        <img src={Busque} alt="Confira a sua posição" class="title-front title-front-busque" />
                        <img src={oTopo} alt="Posição" class="title-back title-back-otopo" style={{paddingLeft:100}} />
                        {/* style="left: 0px; padding-left: 100px;" */}
                    </h1>
                    <h1 class="d-block d-md-none">
                        <img src={busqueOtopo} alt="Posição" class="busque_topo_mobile" />
                    </h1>
                </div>
                <div class="box-character col-auto"></div>
                <div class="box-game col-auto">
                    <div class="btn-game-style">Ganhe<span>Premios!</span></div>
                </div>
            </div>
        </div>
    </section>
    );
}