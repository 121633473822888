import './modal-regulamento.css';
import { useState } from 'react';


export default function ModalRegulamento({closeModalRegulamento}){

    


    return(
        <div className="content-recover-password reg-full">
            
                <div className="header-register">
                        <h5>REGULAMENTO</h5>
                </div>



                <div class="modal-body text-left reg">
                    <p>O presente documento regula o funcionamento da ação a ser realizada pela empresa ATELIÊ DE PROPAGANDA E PUBLICIDADE LTDA. (“ATELIÊ”), com sede em Rua Madre de Deus n. 998ª, Mooca, CEP 03119-001, São Paulo/SP, inscrita no CNPJ sob o n° 04.948.241/0001-18.</p>
                    <ol>
                        <li class="modal-title">OBJETIVO E PRAZO DE EXECUÇÃO
                            <ol>
                                <li>O objetivo da presente ação de incentivo (“CAMPANHA”) é promover atividades lúdicas e de interação entre os colaboradores do ATELIÊ e, também, destes com colaboradores de clientes selecionados.</li>
                                <li>A campanha será executada no seguinte período: de 01/01/2018 a 17/07/2018.</li>
                            </ol>
                        </li>
                        <li class="modal-title">QUEM PODE PARTICIPAR
                            <ol>
                                <li>Participam da Campanha os colaboradores do ATELIÊ e, também, os colaboradores de algumas empresas clientes do ATELIÊ e convidados. Caberá exclusivamente ao ATELIÊ determinar quem serão as pessoas convidadas a participar da “CAMPANHA” e enviar a esses um e-mail contendo respectivo convite e link de acesso para a plataforma online em que a CAMPANHA será desenvolvida.</li>
                                <li>A participação de qualquer convidado na CAMPANHA é facultativa e voluntária. Ao receber o e-mail convite, desejando dela participar, o interessado deverá acessar a plataforma online, criar login e senha de acesso, fornecendo todas as informações solicitadas no formulário que lhe será disponibilizado. Será necessário, ainda, ler e aceitar o presente Regulamento.</li>
                                <li>Tendo em vista tratar-se de uma ação privada, a ser realizada sob total controle e gestão do ATELIÊ, caberá exclusivamente ao ATELIÊ validar todos os cadastros recebidos na plataforma online. Confirmado que o cadastro foi realizado por uma pessoa efetivamente convidada a participar da CAMPANHA e estando aquela pessoa apta a participar, considerando-se aqui os requisitos contidos no item precedente, seu acesso ao sistema será liberado pelo ATELIÊ permitindo-se, assim, que ela possa efetivamente participar da CAMPANHA.</li>
                                <li>Todos os participantes da CAMPANHA concorrem entre si, estando submetidos às mesmas regras de participação e critérios de contemplação, na forma deste Regulamento.</li>
                            </ol>
                        </li>
                        <li class="modal-title">DO JOGO / MECÂNICA
                            <ol>
                                <li>Por meio da plataforma, cada participante poderá opinar (dar ‘palpites’) sobre os possíveis resultados de jogos de futebol de campo, cujas partidas ainda serão disputadas. Na plataforma haverá indicação exata dos jogos que serão considerados para fins da CAMPANHA, assim como quais os times envolvidos, data e horário previstos para a realização da(s) partida(s).</li>
                                <li>O participante deverá preencher o campo disponível para indicação do resultado de cada uma das partidas, ou seja, deverá dar o seu ‘palpite’ sobre o resultado das partidas. Será possível cadastrar e alterar ‘palpites’ para uma partida até 1 hora antes do seu início, após este prazo limite, não será possível corrigir/modificar o placar cadastrado.</li>
                                <li>Depois de concluída cada uma das partidas, o sistema será alimentado pelo ATELIÊ com o resultado oficial das partidas e, a partir desses resultados, atribuirá pontuação em favor dos participantes, considerando-se, para tanto, os seguintes critérios:
                                    <table cellpadding="10">
                                        <tbody><tr>
                                            <td><b>10 pontos</b> para quem acertar o placar exato da partida</td>
                                        </tr>
                                        <tr>
                                            <td><b>07 pontos</b> para quem acertar o resultado e o número de gols de um dos times</td>
                                        </tr>
                                        <tr>
                                            <td><b>05 pontos</b> para quem acertar o resultado, mas errar o placar</td>
                                        </tr>
                                        <tr>
                                            <td><b>02 pontos</b> para quem acertar o número de gols de um dos times</td>
                                        </tr>
                                    </tbody></table>
                                    Exemplo. <br />
                                    Caso o resultado da partida tenha sido: AZUL 3x1 VERMELHO. Os ‘palpites’ abaixo valerão:
                                    <table cellpadding="10">
                                        <tbody><tr>
                                            <td>3x1 –  <b>10 pontos</b>, pois o participante acertou o resultado exato da partida</td>
                                        </tr>
                                        <tr>
                                            <td>1x0 –  <b>05 pontos</b>, pois o participante acertou o vencedor da partida</td>
                                        </tr>
                                        <tr>
                                            <td>3x0 – <b>07 pontos</b>, pois o participante acertou a quantidade de gols de um dos times e ainda a sua vitória (ou empate, em caso de empate)</td>
                                        </tr>
                                        <tr>
                                            <td>0x1 –  <b>02 pontos</b>, pois o participante acertou o placar de um dos times</td>
                                        </tr>
                                        <tr>
                                            <td>0x0 – <b>0 pontos</b>, pois o participante não acertou nada</td>
                                        </tr>
                                    </tbody></table>
                                </li>
                                <li>Caso a partida disponibilizada para o recebimento de ‘palpites’ deva ser decidida por meio de prorrogação e, se necessário, disputa por pênaltis, na forma do Regulamento do Campeonato em que aquela partida está sendo realizada, serão considerados, para fins de pontuação, o resultado daquela partida ao final do período da prorrogação, sendo, portanto, desconsiderado o resultado havido depois de realizada eventual disputa por pênaltis.</li>
                                <li>Os participantes que não inserirem seus ‘palpites’ dentro dos prazos determinados neste Regulamento, terão 0 (zero) pontos atribuídos relativamente à partida sobre a qual não opinou (deu ‘palpites’) ou opinou fora do prazo.</li>
                            </ol>
                        </li>
                        <li class="modal-title">DA APURAÇÃO DO VENCEDOR E PREMIAÇÃO
                            <ol>
                                <li>Ao final do período da CAMPANHA o participante que conquistar o maior número de pontos será considerado vencedor.
                                </li>
                                <li>Havendo participantes empatados, ou seja, com número idêntico de pontos conquistados, serão utilizados os seguintes critérios de desempate:
                                    <ol class="alphabet-list">
                                        <li><b>a)</b> Considera-se vencedor aquele que mais acertou placares ao longo da CAMPANHA; persistindo o empate</li>
                                        <li><b>b)</b> Considera-se vencedor o participante que cadastrou o placar da última partida mais cedo, ou seja, antes; persistindo o empate</li>
                                        <li><b>c)</b> Será realizado um sorteio para determinação do participante vencedor.</li>
                                    </ol>
                                </li>
                                <li>Ao vencedor será atribuído o seguinte prêmio: (1) um vale compras do tipo cartão de crédito pré-pago, bandeira Mastercard, para compras no valor de R$1.000,00 (mil reais).
                                </li>
                                <li>O resultado final da CAMPANHA será divulgado na plataforma e também por e-mail endereçado a todos os participantes, no dia 18/07/2018.
                                </li>
                                <li>O prêmio será entregue ao vencedor em até 30 (trinta) dias contados da data indicada no item precedente. A entrega será feita mediante assinatura de recibo, devendo acontecer na sede do ATELIÊ ou em outro local designado pela referida empresa.
                                </li>
                            </ol>
                        </li>
                        <li class="modal-title">CONDIÇÕES GERAIS
                            <ol>
                                <li>Os profissionais que forem desligados do ATELIÊ e/ou das empresas CLIENTES ao longo da presente CAMPANHA serão automaticamente excluídos da mesma, tendo os seus respectivos desempenhos/pontuação desconsiderados para qualquer fim.
                                </li>
                                <li>A premiação é pessoal e intransferível, não podendo o prêmio ser substituído por qualquer outro ou por dinheiro, em hipótese alguma, assim como também não poderá ser objeto de venda, permuta ou doação, sob pena de adoção das medidas judiciais cabíveis.
                                </li>
                                <li>Caso o participante contemplado esteja impedido, por qualquer que seja o motivo, de receber e/ou usufruir do prêmio a que faria jus nos termos deste regulamento, o prêmio será considerado perdido, não podendo ser atribuído a qualquer outra pessoa.
                                </li>
                                <li>A presente CAMPANHA é uma realização de exclusiva iniciativa e responsabilidade do ATELIÊ, cabendo à referida empresa, de forma soberana, revisar e alterar qualquer regra estabelecida por meio deste Regulamento, se assim entender que se faz necessário para o seu bom desenvolvimento e, ainda, para o atingimento dos objetivos propostos pela empresa.
                                </li>
                                <li>O ATELIÊ se reserva o direito de alterar este regulamento, em todo ou em parte, em razão de mudanças estratégicas ou estruturais da empresa, a qualquer tempo e ao seu exclusivo critério, colocando as novas regras e/ou condições em vigência imediatamente, mediante prévia comunicação aos participantes. Pode, ainda, a ATELIÊ, suspender a CAMPANHA, a seu exclusivo critério, sem que seja devida qualquer indenização ou recomposição a qualquer parte.
                                </li>
                                <li>Qualquer dúvida, divergência ou situação não prevista neste regulamento será conhecida e decidida de forma soberana e irrecorrível pelo ATELIÊ.
                                </li>
                                <li>Os participantes concordam e autorizam o uso de suas imagens em qualquer tipo de mídia e peças publicitárias ou promocionais para a divulgação da sua participação e/ou conquista do prêmio, sem quaisquer ônus para a empresa e/ou pessoas físicas envolvidas com a presente CAMPANHA.
                                </li>
                                <li>A participação nesta CAMPANHA implica na aceitação total e irrestrita de todos os termos deste Regulamento.
                                </li>
                                <li>A responsabilidade do ATELIÊ com relação ao prêmio se encerra no momento da sua entrega ao seu respectivo ganhador. Qualquer reclamação com relação à qualidade, conteúdo ou funcionamento do prêmio recebido deverá ser feita diretamente ao seu respectivo fornecedor, não podendo o ATELIÊ ser responsabilizado.
                                </li>
                                <li>Dúvidas e informações sobre esta CAMPANHA poderão ser obtidos pelos participantes através do e-mail <a href="mailto:contato@palpitepropaganda.com.br">contato@palpitepropaganda.com.br</a>
                                </li>
                            </ol>
                        </li>
                    </ol>
                                    </div>














            <div className="footer-register border-top">
                <button type="button" class="btn-std" data-dismiss="modal" onClick={closeModalRegulamento} >Fechar</button>
            </div>     
            
    </div>
    )
}