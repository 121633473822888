import React, { useEffect, useState } from "react";
import {useHistory} from 'react-router-dom'
import './menu.css';
import logoYellow from '../../assets/images/logos/logo-yellow.png';
import logoBgDark from '../../assets/images/logos/logo-amarelo.png'
import { Link } from "react-router-dom";
import ModalMenu from 'react-modal'
import api from '../../services/api'
import ModalBody from '../../components/modal-menu/modal-menu';

function Menu(props) {
    const history = useHistory()
    const [scrollPage, setScrollPage] = useState(0);

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          margin:0,
          padding:0,    
          width: '100%',
          zIndex:13,
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#321A56',
          overflowX: 'hidden',
          borderRadius: '0px',
          border: 'none',
          height: '100%',
        },
      };

    const [url, setUrl] = useState('');
    const [modalMenuIsOpen, setModalMenuIsOpen] = useState(false);
    const [loadingPoints, setLoadingPoints] = useState(false);
    const [loadingPosition, setLoadingPosition] = useState(false);
    const [position, setPosition] = useState([]);
    const [scouts, setScouts] = useState([]);

    let subtitle;

    useEffect(()=> {
        //get url params to change logo
        const getUrl = window.location.pathname;

        setUrl(getUrl);

        window.scrollTo(0, 0)
    }, [])


    
    useEffect(()=>{
          
        async function loadPoints(){
            setLoadingPoints(true);
            try{
                const response = await api.get('/api/v1/matches/points/my_points')
                setScouts(response.data)
            }catch(e){
                console.log(e)
                if(e.response.status === 401){
                    localStorage.clear()
                    history.push('/')
                }
                setLoadingPoints(false);
            }
            setLoadingPoints(false);
        }
        loadPoints();
      }, [])

      useEffect(()=> {
        async function loadPosition(){
            setLoadingPosition(true);
            try{
                const response = await api.get('/api/v1/ranking')
                setPosition(response.data)
            }catch(e){
                console.log(e)
                setLoadingPosition(false);
            }
            setLoadingPosition(false);
        }

        loadPosition();
      },[])

   

    useEffect(()=>{
      //detect if scroll down
        window.addEventListener('scroll', () => {
             var scrollPage = window.scrollY;
            setScrollPage(scrollPage);
        }
        )
    }, [scrollPage])

    function closeModalMenu() {
        setModalMenuIsOpen(false);
      }

      function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
      }

      function openModalMenu() {
        setModalMenuIsOpen(true);
      }

    return (
        <>
            <header className="App-header">
                <nav class="navbar p-0">
                    <div class="container">
                        <div class="content-align">
                            <a class="navbar-brand dark" href="/">
                                <img className='logo-yellow' src={url == '/jogar' ? logoBgDark : logoYellow} alt="logo" />
                               
                            </a>
                            <div class="navs-right">
                                <div class="navbar-punctuation">
                                    <ul class="list-points">
                                        <li class="li-btn-game d-none d-lg-block fundo-roxo"><span>
                                                <Link to="/jogar" className={`btn-game ${url == '/jogar' ? `hvBgDark` : `hvBgYellow`}`}>Jogue Agora</Link>
                                            </span></li>
                                        <li>Seus Pontos <span>{
                                            loadingPoints ?  <div className="spinner-border text-warning" style={{width:25, height:25}} role="status"> </div> : 
                                            <>{scouts.has_hint ? scouts.points : '-'}</>
                                        }</span></li>
                                        <li>Sua Posição <span>
                                            {
                                                loadingPoints ?  <div className="spinner-border text-warning" style={{width:25, height:25}} role="status"> </div> : 
                                                <>{scouts.has_hint ? <>{scouts.position}º</> : '-'}</>
                                            }
                                            </span></li>
                                    </ul>
                                </div>
                                <div className={`${scrollPage > 0 ? 'navbar-toggler toggle-scroll' : 'navbar-toggler'}`}>
                                    <button class="button-toggle"  type="button" onClick={openModalMenu}></button>
                                </div>
                                <dfn> </dfn>
                            </div>
                        </div>
                    </div>
                </nav>
                <ModalMenu
                isOpen={modalMenuIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModalMenu}
                style={customStyles}
                contentLabel="Registrar"
                >
                    <ModalBody closeMenu={closeModalMenu} />
                </ModalMenu>
            </header>
        </>
    );
}

export default Menu;
