import './modal-menu.css'
import logoYellow from '../../assets/images/logos/logo-yellow.png';
import logoBgDark from '../../assets/images/logos/logo-amarelo.png'
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import ModalRegulamento from 'react-modal';

import api from '../../services/api'
import ModalRegulamentoBody from '../modal-regulamento/modal-regulamento';

import iconClose from '../../assets/images/icons/icon-close.png'

export default function ModalMenu({closeMenu}){
    const [regulamentoOpen, setRegulamentoOpen] = useState(false);
    const [scouts, setScouts] = useState([]);
    const [url, setUrl] = useState('');
    const [loadingPoints, setLoadingPoints] = useState(false);
    let subtitle;

    const customStylesRegulamento = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          margin:0,
          padding:0,
          transform: 'translate(-50%, -50%)',
          overFlow: 'auto',
          

        },
      };



      useEffect(()=>{
          
        async function loadPoints(){
            setLoadingPoints(true);
            try{
                const response = await api.get('/api/v1/matches/points/my_points')
                setScouts(response.data)
            }catch(e){
                console.log(e)
                setLoadingPoints(false)
            }

            setLoadingPoints(false)
        }
        loadPoints();
      }, [])

    function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
    }

    function closeModal() {
    setRegulamentoOpen(false);
    }

    function openModal(){
        setRegulamentoOpen(true);
    }
    useEffect(()=> {
        //get url params to change logo
        const getUrl = window.location.pathname;

        setUrl(getUrl);
        console.log(getUrl);
    }, [])

    function logout(){

        localStorage.removeItem('palpiteepropaganda-token');
        window.location.reload();
    }

    
    return(
        <div className='container-fluid'>
            <header className="App-header">
                <nav class="navbar p-0">
                    <div class="container">
                        <div class="content-align">
                            <a class="navbar-brand dark" href="/">
                                <img className='logo-yellow' src={logoBgDark} alt="logo" />
                            
                            </a>
                            <div class="navs-right">
                                <div class="navbar-punctuation">
                                    <ul class="list-points">
                                        <li class="li-btn-game d-none d-lg-block fundo-roxo"><span>
                                                <a href="/jogar" className="btn-game hvYellowCWhite yellow">Jogue Agora</a>
                                            </span></li>
                                        <li>Seus Pontos <span className='yellow'>{
                                            loadingPoints ?  <div className="spinner-border text-warning" style={{width:25, height:25}} role="status"> </div> : <>{scouts.has_hint ? scouts.points : '-'}</>
                                        }</span></li>
                                        <li>Sua Posição <span className='yellow'>
                                        {
                                            loadingPoints ?  <div className="spinner-border text-warning" style={{width:25, height:25}} role="status"> </div> : <>{scouts.has_hint ? <>{scouts.position}º</> : '-'}</>
                                        }
                                             </span></li>
                                    </ul>
                                </div>
                                <div class="navbar-toggler">
                                    <button class="button-toggle" onClick={closeMenu} type="button"></button>
                                </div>
                                <dfn> </dfn>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
            <div className='container'>
            <div class="content-menu">
                <div class="row justify-content-md-center">
                    <div class="col-12 col-lg-10">
                        <div class="position-relative">
                            <div class="row row-content align-items-center justify-content-between">
                                <ul class="navbar-nav col-12 col-sm-9 col-md-6">
                                    <li class="nav-item">
                                        <a class={`nav-link ${
                                            url == '/' ? 'active' : ''
                                        }`} href="/">Home</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link button-toggle " href="/#mecanica"  onClick={closeMenu} data-dismiss="modal" aria-label="Close">O bolão</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class={`nav-link ${
                                            url == '/jogar' ? 'active' : ''
                                        }`} href="/jogar">Jogue Agora</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class={`nav-link ${
                                            url == '/estatisticas' ? 'active' : ''
                                        }`
                                        } href="/estatisticas">Estatísticas</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class={`nav-link ${
                                            url == '/ranking' ? 'active' : ''
                                        }`
                                        } href="/ranking">Ranking</a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="javascript:void(0);" class="nav-link nav-regulation" onClick={openModal}>Regulamento</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link nav-logout" onClick={logout} href="#">Sair</a>
                                    </li>
                                </ul>
                                <div class="box-image-menu">
                                    <img src="https://resources-palpiteepropaganda.promosatelie.com.br/images/frontend/banners/personagem-menu.png" alt="Menu" />
                                </div>
                                <div class="navbar-toggler fechar_menu col-12 col-md-3">
                                    <button class="button-toggle" onClick={closeMenu}  type="button">
                                        <img src={iconClose} style={{width:'34px'}} />
                                        Fechar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <ModalRegulamento 
                        isOpen={regulamentoOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStylesRegulamento}
                        contentLabel="Registrar"
        >
            <ModalRegulamentoBody closeModalRegulamento={closeModal}/>
        </ModalRegulamento>
        </div>
    )
}