import './jogar.css'
import OwlCarousel from 'react-owl-carousel2'
import api from '../../services/api'
import 'react-owl-carousel2/lib/styles.css'


import Menu from '../../components/menu/menu';
import BannerJogar from '../../components/banner-jogar/banner-jogar';
import Footer from '../../components/footer/footer'

import Slide from '../../components/slider-favoritas/slider';
import { useEffect, useState } from 'react';

import Jogos from '../../components/jogos/jogos';
import Playoffs from '../../components/playoffs/playoffs';

function Jogar() {
    const [matches, setMatches] = useState([]);
    const [scouts, setScouts] = useState([]);
    const [loadMatches, setLoadMatches] = useState(false);
    const [loadingScouts, setLoadingScouts] = useState(false);

    useEffect(()=> {
        window.scrollTo(0, 0)

        async function loadMatches(){
          setLoadMatches(true);
          try{
            
            const response = await api.get('/api/v1/matches')

            setMatches(response.data)


          }catch(e){
            console.log(e)
            setLoadMatches(false)
          }

          setLoadMatches(false)


        }

        async function loadPoints(){
            setLoadingScouts(true);
            try{
                const response = await api.get('/api/v1/matches/points/my_points')
                setScouts(response.data)
            }catch(e){
                console.log(e)
                setLoadingScouts(false)
            }
            setLoadingScouts(false)
        }
        
        loadPoints();
        loadMatches();
    }, [])

    const optJogos = {
        items: 1,
        autoHeight: true,
        autoHeightClass: 'owl-height',
        nav: true,
        navText:['<span><</span>', '<span>></span>'],
        mouseDrag: false
    };


    return (
        <>
            <Menu />
            <BannerJogar />
            <article className="page-jogar">

                <section className='sec-hunches'>
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div class="confira-pts-title">
                                <div class="box-title">
                                    <h2>Palpite nos jogos abaixo para marcar pontos</h2>
                                    {/* <p>Mas, lembre-se: precisa ser no máximo 1 hora antes da partida acontecer. Capricha!</p> */}
                                    {/* <span>Confira quantos pontos cada palpite lhe rendeu até o momento.</span> */}
                                </div>
                            </div>

                            <div class="panel-position col-12">
                                <div class="panel-yellow">
                                    {loadingScouts ? (<><h2>Carregando...</h2></>) : 
                                    (<>
                                        {scouts.has_hint === true ? (<h2>Você está em {scouts.position}º lugar, com <span className=''>{scouts.points} Pontos</span></h2>) : (<h2>LEMBRE-SE: VOCÊ PRECISAR FAZER SEUS PALPITES <br /> ATÉ 1 HORA ANTES DA PARTIDA ACONTECER.</h2>)}
                                    </>)}

                                    
                                </div>
                            </div>

                            <OwlCarousel options={optJogos}>
                            <div className='item jogos_item'>
                            <div className='table-hunches col-12'>
                                <div className="palpites-jogos">
                                    <div className="prev-next">
                                        <h1>FASE DE GRUPOS</h1>
                                    </div>

                                    <div className="inputs-jogos">

                                        <div className="single">
                                            <div className="panel-results">
                                                <div className="tab-pane">
                                                    <div className="row">
                                                        {/* Spinner */}
                                                        {loadMatches &&
                                                        <div className='spinner-matches'>
                                                         <div className="spinner-border text-primary" role="status">
                                                            <span className="sr-only"></span>
                                                        </div>
                                                        </div>}


                                                        <div className='panel-body col-12'>

                                                            <Jogos data={matches} fase="World Cup 2022, A" titulo="Grupo A" descricao="Placares da Fase de Grupo" />
                                                            <Jogos data={matches} fase="World Cup 2022, B" titulo="Grupo B" descricao="Placares da Fase de Grupo" />
                                                            <Jogos data={matches} fase="World Cup 2022, C" titulo="Grupo C" descricao="Placares da Fase de Grupo" />
                                                            <Jogos data={matches} fase="World Cup 2022, D" titulo="Grupo D" descricao="Placares da Fase de Grupo" />
                                                            <Jogos data={matches} fase="World Cup 2022, E" titulo="Grupo E" descricao="Placares da Fase de Grupo" />
                                                            <Jogos data={matches} fase="World Cup 2022, F" titulo="Grupo F" descricao="Placares da Fase de Grupo" />
                                                            <Jogos data={matches} fase="World Cup 2022, G" titulo="Grupo G" descricao="Placares da Fase de Grupo" />
                                                            <Jogos data={matches} fase="World Cup 2022, H" titulo="Grupo H" descricao="Placares da Fase de Grupo" />

                                                            </div>
                                                    </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
              

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='item jogos_item'> 
                            <div className='table-hunches col-12'>
                                <div className="palpites-jogos">
                                    <div className="prev-next">
                                        <h1>OITAVAS DE FINAL</h1>
                                    </div>

                                    <div className="inputs-jogos">

                                        <div className="single">
                                            <div className="panel-results">
                                                <div className="tab-pane">
                                                    <div className="row">
                                                        {/* Spinner */}
                                                        {loadMatches &&
                                                        <div className='spinner-matches'>
                                                         <div className="spinner-border text-primary" role="status">
                                                            <span className="sr-only"></span>
                                                        </div>
                                                        </div>}


                                                        <div className='panel-body col-12'>

                                                            <Playoffs data={matches} fase="World Cup 2022, Knockout stage" titulo="" descricao="Placares da Fase Final" round={46854}/>

                                                            </div>
                                                    </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
              

                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className='item jogos_item'> 
                            <div className='table-hunches col-12'>
                                <div className="palpites-jogos">
                                    <div className="prev-next">
                                        <h1>QUARTAS DE FINAL</h1>
                                    </div>

                                    <div className="inputs-jogos">

                                        <div className="single">
                                            <div className="panel-results">
                                                <div className="tab-pane">
                                                    <div className="row">
                                                        {/* Spinner */}
                                                        {loadMatches &&
                                                        <div className='spinner-matches'>
                                                         <div className="spinner-border text-primary" role="status">
                                                            <span className="sr-only"></span>
                                                        </div>
                                                        </div>}


                                                        <div className='panel-body col-12'>

                                                            <Playoffs data={matches} fase="World Cup 2022, Knockout stage" titulo="" descricao="Placares da Fase Final" round={46850}/>

                                                            </div>
                                                    </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
              

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='item jogos_item'> 
                            <div className='table-hunches col-12'>
                                <div className="palpites-jogos">
                                    <div className="prev-next">
                                        <h1>SEMI FINAL</h1>
                                    </div>

                                    <div className="inputs-jogos">

                                        <div className="single">
                                            <div className="panel-results">
                                                <div className="tab-pane">
                                                    <div className="row">
                                                        {/* Spinner */}
                                                        {loadMatches &&
                                                        <div className='spinner-matches'>
                                                         <div className="spinner-border text-primary" role="status">
                                                            <span className="sr-only"></span>
                                                        </div>
                                                        </div>}


                                                        <div className='panel-body col-12'>

                                                            <Playoffs data={matches} fase="World Cup 2022, Knockout stage" titulo="" descricao="Placares da Fase Final" round={46849}/>

                                                            </div>
                                                    </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
              

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='item jogos_item'> 
                            <div className='table-hunches col-12'>
                                <div className="palpites-jogos">
                                    <div className="prev-next">
                                        <h1>TERCEIRO LUGAR</h1>
                                    </div>

                                    <div className="inputs-jogos">

                                        <div className="single">
                                            <div className="panel-results">
                                                <div className="tab-pane">
                                                    <div className="row">
                                                        {/* Spinner */}
                                                        {loadMatches &&
                                                        <div className='spinner-matches'>
                                                         <div className="spinner-border text-primary" role="status">
                                                            <span className="sr-only"></span>
                                                        </div>
                                                        </div>}


                                                        <div className='panel-body col-12'>

                                                            <Playoffs data={matches} fase="World Cup 2022, Knockout stage" titulo="" descricao="Placares da Fase Final" round={46848}/>

                                                            </div>
                                                    </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
              

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='item jogos_item'> 
                            <div className='table-hunches col-12'>
                                <div className="palpites-jogos">
                                    <div className="prev-next">
                                        <h1>FINAL</h1>
                                    </div>

                                    <div className="inputs-jogos">

                                        <div className="single">
                                            <div className="panel-results">
                                                <div className="tab-pane">
                                                    <div className="row">
                                                        {/* Spinner */}
                                                        {loadMatches &&
                                                        <div className='spinner-matches'>
                                                         <div className="spinner-border text-primary" role="status">
                                                            <span className="sr-only"></span>
                                                        </div>
                                                        </div>}


                                                        <div className='panel-body col-12'>

                                                            <Playoffs data={matches} fase="World Cup 2022, Knockout stage" titulo="" descricao="Placares da Fase Final" round={46852}/>

                                                            </div>
                                                    </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
              

                                        </div>
                                    </div>
                                </div>
                            </div>
                            </OwlCarousel>
                            </div>
                        </div>
                </section>










                <Slide />

            </article>
            <Footer />
        </>

    )
}

export default Jogar;