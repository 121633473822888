import './estatisticas.css'
import OwlCarousel from 'react-owl-carousel2'
import api from '../../services/api'
import 'react-owl-carousel2/lib/styles.css'


import Menu from '../../components/menu/menu';
import BannerEstatisticas from '../../components/banner-estatisticas/banner-estatisticas';
import Footer from '../../components/footer/footer'
import Slide from '../../components/slider-favoritas/slider';
import TabelaSingle from '../../components/tabela_single/tabela';
import { useEffect, useState } from 'react';

function Estatisticas() {
    const [table, setTable] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);


    useEffect(()=> {
        
        async function loadTable(){        
            setLoadingTable(true);    
            try{
                const response = await api.get('/api/v1/matches/statistics/table')
                setTable(response.data)
            }catch(e){
                console.log(e)
                setLoadingTable(false)
            }
            setLoadingTable(false)
        }
        loadTable();
    }, [])

    
    return (
        <>
            <Menu />
            <BannerEstatisticas />
            <article className="page-estat">

                <section className='sec-estat'>
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div class="col-12 col-lg-10">
                                <div class="box-title">
                                    <h2>Tabela de Classificação Copa do Mundo 2022</h2>

                                </div>
                            </div>
                            <div className='table-hunches col-12'>
                                <div className='content-panels'>
                                    {
                                        loadingTable ?
                                        <div className='loading text-center'>
                                           <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </div>
                                        :
                                        <div className='item-panel tab-pane'>
                                            <TabelaSingle data={table} grupo="World Cup 2022, A" titulo="Grupo A" />
                                            <TabelaSingle data={table} grupo="World Cup 2022, B" titulo="Grupo B" />
                                            <TabelaSingle data={table} grupo="World Cup 2022, C" titulo="Grupo C" />
                                            <TabelaSingle data={table} grupo="World Cup 2022, D" titulo="Grupo D" />
                                            <TabelaSingle data={table} grupo="World Cup 2022, E" titulo="Grupo E" />
                                            <TabelaSingle data={table} grupo="World Cup 2022, F" titulo="Grupo F" />
                                            <TabelaSingle data={table} grupo="World Cup 2022, G" titulo="Grupo G" />
                                            <TabelaSingle data={table} grupo="World Cup 2022, H" titulo="Grupo H" />
                                        </div>

                                    }
                                    {/* <div class="legenda-grupo">
                                        <span class="legenda-label">
                                            <span class="tabela-icone-ult tabela-icone-circulo tabela-icone-v"></span>
                                            vitoria
                                        </span>
                                        <span class="legenda-label">
                                            <span class="tabela-icone-ult tabela-icone-circulo tabela-icone-e"></span>
                                            empate
                                        </span>
                                        <span class="legenda-label">
                                            <span class="tabela-icone-ult tabela-icone-circulo tabela-icone-d"></span>
                                            derrota
                                        </span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <Slide />

            </article>
            <Footer />
        </>

    )
}

export default Estatisticas;