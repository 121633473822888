import './home.css'

import Menu from '../../components/menu/menu';
import BannerHome from '../../components/banner-home/banner-home';
import Footer from '../../components/footer/footer'
import Slide from '../../components/slider-favoritas/slider';
import api from '../../services/api'
import { useEffect } from 'react';

function Home() {


    useEffect(()=> {

        async function loadUser() {
            try{

                const response = await api.get('/api/v1/user');
                

            }catch(e){
                console.log(e);
            }
        }

        loadUser();
    }, [])

    return (
        <>
            <Menu />
            <BannerHome />
            <article className="page-home">
                <section className="sec-awards">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-12 col-lg-10">
                                <div className="row align-items-center">
                                    <div className="box-image col-12 col-lg-5">
                                        <img src="https://resources-palpiteepropaganda.promosatelie.com.br/images/frontend/premios/palpite-e-propaganda-pack-premios-new 2.png" alt="palpite-e-propaganda-pack-premios" />
                                    </div>
                                    <div className="box-text col-12 col-lg-7">
                                        <h2>VAMOS TORCER JUNTOS!</h2>
                                        <p>A competição mais emocionante do mundo vai começar. <br />
                                            É claro que estamos falando do Palpite e Propaganda. <br />
                                            Entre em campo com a gente e dê os seus palpites para concorrer <br />
                                            a prêmios que vão fazer o seu coração bater mais forte.</p>

                                        <div className="box-image d-block d-lg-none">
                                            <img src="https://resources-palpiteepropaganda.promosatelie.com.br/images/frontend/premios/palpite-e-propaganda-pack-premios-new 2.png" alt="palpite-e-propaganda-pack-premios" />
                                        </div>

                                        <h5>TERMINE ENTRE OS 4 MELHORES PONTUADORES <br /> DO RANKING E GANHE:</h5>
                                        <dfn>
                                            <ul>
                                                <li><a href="#mecanica" className="link-std scroll">1º lugar: Smart TV LG 50" com inteligência artificial</a></li>
                                                <li><a href="#mecanica" className="link-std scroll">2º lugar: Xbox Serie S + game Fifa 22</a></li>
                                                <li><a href="#mecanica" className="link-std scroll">3º lugar: Cervejeira Consul</a></li>
                                                <li><a href="#mecanica" className="link-std scroll">4º lugar: Vale Netshoes de R$300,00</a></li>
                                            </ul>
                                        </dfn>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>







                <section className="sec-mechanical" id="mecanica">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-12 col-lg-10">
                                <div className="row">
                                    <div className="box-title col-12">
                                        <h2>COMO JOGAR</h2>
                                        <p>Basta registrar os seus palpites para os jogos em até 1h (60 minutos) antes do início de cada partida.</p>
                                    </div>
                                    <div className="w-100"></div>
                                    <div className="box-text col-12 col-lg-6">
                                        <p>Você ganha pontos da seguinte forma:</p>
                                        <table className="table-simple">
                                            <tbody>
                                                <tr>
                                                    <th><span>10</span> Pontos</th>
                                                    <td>Acertando o placar da partida</td>
                                                </tr>
                                                <tr>
                                                    <th><span>07</span> Pontos</th>
                                                    <td>Acertando o resultado e o número <br /> de gols de um dos times</td>
                                                </tr>
                                                <tr>
                                                    <th><span>05</span> Pontos</th>
                                                    <td>Acertando o resultado do jogo,<br /> mas não o placar</td>
                                                </tr>
                                                <tr>
                                                    <th><span>02</span> Pontos</th>
                                                    <td>Acertando o número de gols <br /> de um dos times</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <span className="span-bold">Veja o exemplo</span>
                                        <p>O resultado de uma suposta partida foi <strong>Brasil 2x1 Portugal</strong>.Os palpites abaixo valerão:</p>
                                        <table className="table-pointing">
                                            <tbody>
                                                <tr>
                                                    <th><span>Brasil 2x1 Portugal -</span> 10 pontos</th>
                                                    <td>O participante acertou o placar da partida</td>
                                                </tr>
                                                <tr>
                                                    <th><span>Brasil 3x1 Portugal -</span> 7 pontos</th>
                                                    <td>O participante acertou o resultado (vitória do Brasil) e ainda a quantidade de gols de um dos times (Portugal).</td>
                                                </tr>
                                                <tr>
                                                    <th><span>Brasil 1x0 Portugal -</span> 5 pontos</th>
                                                    <td>O participante acertou o resultado do jogo (vitória do Brasil).</td>
                                                </tr>
                                                <tr>
                                                    <th><span>Brasil 0x1 Portugal-</span> 2 pontos</th>
                                                    <td>O participante acertou apenas a quatidade de gols <br /> de um dos times (Portugal).</td>
                                                </tr>
                                                <tr>
                                                    <th><span>Brasil 0x0 Portugal -</span> 0 pontos</th>
                                                    <td>Putz, o participante errou tudo!</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="box-image col-12 col-lg-6">
                                        <div className="box-purple">
                                            <div className="img-square top"><img src="https://resources-palpiteepropaganda.promosatelie.com.br/images/frontend/squares/square-mecanica-top.png" alt="" /></div>
                                            <a href="/jogar" className="btn-game">Jogue<span>Agora</span></a>
                                            <figure>
                                                <img src="https://resources-palpiteepropaganda.promosatelie.com.br/images/frontend/banners/banner-home-mecanica.png" alt="Jogue agora" />
                                            </figure>
                                            <div className="img-square bottom"><img src="https://resources-palpiteepropaganda.promosatelie.com.br/images/frontend/squares/square-mecanica-bottom.png" alt="" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <section className="sec-infos">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-12 col-lg-10">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="single single-fase home">
                                            <h3>Fase eliminatória (oitavas, quatas, semi e final)</h3>
                                            <p>A mecânica será a mesma para a segunda fase da competição. Em caso de empate nos 90 minutos, <br /> será válido o resultado ao fim da prorrogação, desconsiderando uma eventual disputa de pênaltis.</p>
                                        </div>

                                        <div className="w-100"></div>
                                        <div className="jumbotron">
                                            <p><strong>Atenção!</strong></p>
                                            <p>O participante que não registrar seus placares até o fim do tempo <br /> determinado, marcará 0 pontos nas partidas não preenchidas.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>




            <Slide />

            </article>
            <Footer />
        </>

    )
}

export default Home;