import React, {useState} from 'react';
import './login.css';
import ModalRegister from 'react-modal';
import ModalRecoverPassword from 'react-modal';
import api from '../../services/api'
import Swal from 'sweetalert2'
import InputMask from 'react-input-mask'

import {login} from '../../services/auth'
import {Link, useHistory } from 'react-router-dom'

import LogoPalpite from '../../assets/images/logos/logo-amarelo2.png';

import {useForm} from 'react-hook-form'
import ModalRegistrar from '../../components/modal-registrar/modal-registrar';
import ModalRecoverPasswordBody from '../../components/modal-recover-password/modal-recover-password';

export default function Login(openModal){
    const {register, handleSubmit, formState:{errors, isSubmitting}} = useForm();

    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalIsOpenRecoverPassword, setModalIsOpenRecoverPassword] = useState(false);


    const history = useHistory();

    const [isLoadingLogin, setIsLoadingLogin] = useState(false);


    const [error, setError] = useState(0);



    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          margin:0,
          padding:0,
          transform: 'translate(-50%, -50%)',
          maxWidth: '100%'
        },
      };
    const customStylesRecoverPassowrd = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          margin:0,
          padding:0,
          transform: 'translate(-50%, -50%)',
          maxWidth:'100%'
        },
      };

      function openModal() {
        setIsOpen(true);
      }
      function openModalRecoverPassword() {
        setModalIsOpenRecoverPassword(true);
      }
    
      function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
      }
    
      function closeModal() {
        setIsOpen(false);
      }
      function closeModalRecoverPassword() {
        setModalIsOpenRecoverPassword(false);
      }
      

     async function handleLogin(data){
  
        
        setIsLoadingLogin(true)
        console.log(data)
        const {email, password} = data;
        try{
            const response = await api.post(`/api/v1/auth/login`, {
                email,
                password
            })
            login(response.data.access_token);
            console.log(response.data.access_token)
            history.push("/");
        
        }catch(error){
            setIsLoadingLogin(false)
            if(error.response.status === 403){
                Swal.fire({
                    title:error.response.data.title,
                    text: error.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                    })
            }
            else if(error.response.status === 406){
                Swal.fire({
                    title: `Ops...`,
                    text: `Preencha todos os campos`,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                    })
            }
            else{
                Swal.fire({
                    title: `Ops...`,
                    text: 'Ocorreu um erro, tente novamente.',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                    })                
            }

           
        }finally{
            setIsLoadingLogin(false)
        }

      }


    return(
        <div className='login-full'>
            <div className="container container-login">
            <div class="content-page">
                <div class="row justify-content-start">
                    <div class="col col-md-10 offset-md-1 col-lg-9 offset-lg-1 col-xl-8 offset-xl-1">
                        <main>
                            <h1><img src={LogoPalpite} alt="Palpite Propaganda" /></h1>
                            
                        </main>
                        <div className="box-login">
                            <div class="nopad col-12 col-md-7">
                                
                                    <div className="row">
                                        <form className="form-std form-login" onSubmit={handleSubmit(handleLogin)}>
                                        <div className="form-group col-12">
                                            <label for="inputCPF">E-mail</label>
                                            <input 
                                            {...register("email", {
                                                required: "Email Obrigatório",
                                                pattern: {
                                                    value: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                                                    message: "Email inválido!"
                                                    }
                                                })}
                                            type="email" id="email" name="email"  class="form-control" />
                                            {errors.email && <span  className="msgs-error-validate">{errors.email.message}</span> }
                                        </div>
                                        <div class="form-group col-12">
                                            <label for="inputPassword">Senha</label>
                                            <input 
                                            {...register("password", {
                                                required: "Senha Obrigatória",
                                                minLength: {
                                                    value: 6,
                                                    message: "Senha deve ter no mínimo 6 caracteres"
                                                },
                                                maxLength: {
                                                    value: 20,
                                                    message: "Senha deve ter no máximo 20 caracteres"
                                                }
                                            })}
                                            type="password" id="password" name="password" class="form-control" />
                                            {errors.password && <span  className="msgs-error-validate">{errors.password.message}</span> }
                                        </div>
                                        <div class="form-group col-12">
                                            <div class="row">
                                                <div class="elem elem-button col-xl-12 col-sm-6 entrar">
                                                    <button 
                                                    
                                                    type="submit" name="save" className="btn-std">
                                                        {
                                                            isLoadingLogin ?
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            :
                                                            <span>Entrar</span>
                                                        }
                                                    </button>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        </form>

                                    </div>
                                
                            </div>
                        </div>
                        <div class="elem-link link-password">
                            <a href="javascript:void(0);" class="link-std" data-toggle="modal" data-target="#modalForgotPassword" onClick={openModalRecoverPassword }>Esqueceu a senha? Clique aqui</a>
                            <a href="javascript:void(0);" class="link-std" data-toggle="modal" data-target="#modalRegister"  onClick={openModal}>Primeiro acesso? Efetue o seu cadastro aqui.</a>
                            <p>Dúvidas: <a href="mailto:contato@palpitepropaganda.com.br">contato@palpitepropaganda.com.br</a></p>
                        </div>
                    </div>
                </div>
            </div>
            </div>

            <ModalRegister
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Registrar"
            >
                <ModalRegistrar  
                    onCloseRegisterModal={closeModal}
                />
                                            
            </ModalRegister>

            <ModalRecoverPassword
                isOpen={modalIsOpenRecoverPassword}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModalRecoverPassword}  
                style={customStylesRecoverPassowrd}
                contentLabel="Recuperar Senha"
            >
                <ModalRecoverPasswordBody closeModalRecoverPasswordBody={closeModalRecoverPassword} />
            </ModalRecoverPassword>
        </div>
    )
}