import './ranking.css'
import OwlCarousel from 'react-owl-carousel2'
import api from '../../services/api'
import 'react-owl-carousel2/lib/styles.css'


import Menu from '../../components/menu/menu';
import BannerRanking from '../../components/banner-ranking/banner-ranking';
import Footer from '../../components/footer/footer'
import Slide from '../../components/slider-favoritas/slider';
import { useEffect, useMemo, useState } from 'react';

function Ranking() {
    const [ranking, setRanking] = useState([]);
    const [loadingRanking, setLoadingRanking] = useState(false);
    const [search, setSearch] = useState('');

    const [scouts, setScouts] = useState([]);
    const [loadingPoints, setLoadingPoints] = useState(false);

    useEffect(()=> {

        async function loadRanking(){
            setLoadingRanking(true);
            try{
                const response = await api.get('/api/v1/ranking')
                setRanking(response.data)
            }catch(e){
                console.log(e)
                setLoadingRanking(false)
            }
            setLoadingRanking(false)
        }

        loadRanking();
    },[])


    useEffect(()=>{
          
        async function loadPoints(){
            setLoadingPoints(true);
            try{
                const response = await api.get('/api/v1/matches/points/my_points')
                setScouts(response.data)
            }catch(e){
                console.log(e)
                setLoadingPoints(false);
            }
            setLoadingPoints(false);
        }
        loadPoints();
      }, [])

    const rankingFilter = useMemo(() => {
        return ranking.filter(rank => 
            rank.name.toLowerCase().includes(search.toLowerCase())
            );
    }, [search, ranking]) 




    return (
        <>
            <Menu />
            <article className="page-ranking">
                <BannerRanking />

                <section class="sec-ranking">
                    <div class="container">
                        <div class="row justify-content-md-center">
                            <div class="col-12 col-lg-10">
                                <div class="row">
                                    <div class="panel-position col-12">
                                        <div class="panel-purple">
                                            { loadingPoints ? 
                                            (<>
                                             <h2>Carregando...</h2>
                                            </>) :
                                             (<>
                                             
                                            {scouts.has_hint === true ? (<h2>Você está em {scouts.position}º lugar, com <span className='points_ranking'>{scouts.points} Pontos</span></h2>) : (<h2>Você ainda não jogou.<br /> dê seus palpites e suba no ranking.</h2>)}
                                             </>)}

                                        </div>
                                    </div>
                                    {loadingRanking ?
                                    <div className='spinner-matches'>
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                    </div>
                                    :
                                    (<>
                                                        
                                    <div class="content-table col-12">
                                        <div class="table table-std">
                                            <div id="table-advanced_wrapper" class="dataTables_wrapper">
                                                <div class="top row">
                                                    <div id="table-advanced_filter" class="dataTables_filter col-6 nopad text-left" style={{paddingLeft:5}}>
                                                        <label>
                                                            <input type="search" className="" placeholder="Busque aqui..." aria-controls="table-advanced"  value={search} onChange={(ev) => setSearch(ev.target.value)}/>
                                                        </label>
                                                        </div>
                                                                {/* <div class="dataTables_length col-6 text-right" id="table-advanced_length">
                                                                    <label>Mostrar 
                                                                    <select name="table-advanced_length" aria-controls="table-advanced" class="select2-hidden-accessible" tabindex="-1" aria-hidden="true">
                                                                        <option value="10">10</option>
                                                                        <option value="25">25</option>
                                                                        <option value="50">50</option>
                                                                        <option value="100">100</option>
                                                                        <option value="-1">Todos</option>
                                                                    </select>

                                                                    </label></div> */}

                                                <div class="clear"></div></div>
                                                {rankingFilter.length === 0 ? (<><h1 className='text-center'>Opss! Não encontramos nenhum registro.</h1></>) :
                                                 (<>
                                                 
                                                <div class="table-response">

                                                <table id="table-advanced" data-list="https://palpiteepropaganda.promosatelie.com.br/ranking/rankTable" class="dataTable" role="grid" aria-describedby="table-advanced_info" style={{width:946}}>
                                                <thead>
                                                    <tr role="row"><th class="sorting_asc" tabindex="0" aria-controls="table-advanced" rowspan="1" colspan="1" aria-label="Pos: Ativar a ordenação decrescente da coluna" style={{width:90}}  aria-sort="ascending">Pos</th><th class="sorting" tabindex="0" aria-controls="table-advanced" rowspan="1" colspan="1" aria-label="Nome: Ativar a ordenação crescente da coluna" style={{width:'664px'}}>Nome</th><th class="sorting" tabindex="0" aria-controls="table-advanced" rowspan="1" colspan="1" aria-label="Pontos: Ativar a ordenação crescente da coluna"  style={{width:192}}>Pontos</th></tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        rankingFilter.map((item, index) => (
                                                            <tr role="row" className={
                                                                index % 2 === 0 ? 'even' : 'odd'
                                                            }>
                                                                <td class="sorting_1">{item.position}º</td>
                                                                <td>{item.name}</td>
                                                                <td>{item.points}</td>
                                                            </tr>
                                                        ))
                                                        
                                                        
                                                    }
                                                    
                                                    {/* <tr role="row" class="odd">
                                                        <td class="sorting_1">1</td>
                                                        <td>Caio Magalhaes </td>
                                                        <td>359</td>
                                                    </tr>
                                                    <tr role="row" class="even">
                                                        <td class="sorting_1">2</td>
                                                        <td>GABRIEL FERRARI </td>
                                                        <td>104</td>
                                                    </tr>
                                                    <tr role="row" class="odd">
                                                        <td class="sorting_1">3</td>
                                                        <td>Amanda Esteves </td>
                                                        <td>36</td>
                                                    </tr>
                                                    <tr role="row" class="even">
                                                        <td class="sorting_1">4</td>
                                                        <td>Larissa Tenuta </td>
                                                        <td>34</td>
                                                    </tr>
                                                    <tr role="row" class="odd">
                                                        <td class="sorting_1">5</td>
                                                        <td>Paola Ventura </td>
                                                        <td>34</td>
                                                    </tr>
                                                    <tr role="row" class="even">
                                                        <td class="sorting_1">6</td>
                                                        <td>Eva Rodrigues </td>
                                                        <td>32</td>
                                                    </tr>
                                                    <tr role="row" class="odd">
                                                        <td class="sorting_1">7</td>
                                                        <td>Claudio Teste Um </td>
                                                        <td>26</td>
                                                    </tr> */}
                                                  </tbody>
                                                  </table>
                                                  </div>
                                                  <div class="bottom">
                                                      <div class="dataTables_info" id="table-advanced_info" role="status" aria-live="polite">Mostrando um total de <b>{rankingFilter.length}</b> registros</div>
                                                      {/* <div class="dataTables_paginate paging_simple_numbers" id="table-advanced_paginate">
                                                          <a class="paginate_button previous disabled" aria-controls="table-advanced" data-dt-idx="0" tabindex="0" id="table-advanced_previous">Anterior</a>
                                                          <span><a class="paginate_button current" aria-controls="table-advanced" data-dt-idx="1" tabindex="0">1</a></span>
                                                          <a class="paginate_button next disabled" aria-controls="table-advanced" data-dt-idx="2" tabindex="0" id="table-advanced_next">Próximo</a>
                                                    </div> */}
                                                      <div class="clear"></div>
                                                  </div>
                                                 </>)}
                                                      
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    </>)
                                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Slide />


            </article>
            <Footer />
        </>

    )
}

export default Ranking;