import axios from 'axios'
import {getToken} from './auth'
import apiUrl from './apiUrl'
const api = axios.create({
    baseURL: apiUrl
});
api.interceptors.request.use(async config => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

export default api;
