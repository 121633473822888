import React, {useState} from 'react';
import ModalRegister from 'react-modal';
import ModalRecoverPassword from 'react-modal';
import api from '../../services/api'
import Swal from 'sweetalert2'
import InputMask from 'react-input-mask'

import {login} from '../../services/auth'
import {Link, useHistory } from 'react-router-dom'

import {useForm} from 'react-hook-form'


export default function ModalRegistrar({onCloseRegisterModal}){
 
    const {watch, register, handleSubmit, formState,  formState:{errors, isValid}} = useForm({mode:"all", reValidateMode:"onBlur"});

    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalIsOpenRecoverPassword, setModalIsOpenRecoverPassword] = useState(false);

    const [isSendingRegister, setIsSendingRegister] = useState(false);

    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirmation, setPasswordConfirmation] = useState('');
    const [taxid, setTaxId] = useState('');
    const [email, setEmail] = useState('');

    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [passwordConfirmationError, setPasswordConfirmationError] = useState(false);
    const [taxIdError, setTaxIdError] = useState(false);
    const [nameError, setNameError] = useState(false);


    const [error, setError] = useState(0);
    function closeModal() {
        setIsOpen(false);
   
      }
      const handleRegister = () => {
        handleSubmit(async(e)=>{
            console.log(name, email, taxid, password, password_confirmation);
            setIsSendingRegister(true);
            try{
                    
                const response = await api.post(`/api/v1/auth/register`, {
                    name,
                    email,
                    taxid: taxid.replace(/[^\d]+/g,''),
                    password,
                    password_confirmation,
                    regulation: 1
                })
                console.log('success');
                Swal.fire({
                    icon: 'success',
                    title: 'Cadastro realizado com sucesso!',
                    text:  `Aguarde a aprovação do administrador.`,
                  })
            }catch(err){
                Swal.fire({
                    icon: 'error',
                    title: 'Ops...',
                    text:  `${err.response.data.error.password ? err.response.data.error.password[0] : ''} ${err.response.data.error.email ? err.response.data.error.email[0] : ''} ${err.response.data.error.taxid ? err.response.data.error.taxid[0].replace('O campo taxid', 'O cpf') : ''} ${err.response.data.error.name ? err.response.data.error.name[0] : ''}`,
                  })
                  setIsSendingRegister(false);
                  return

            }
            setIsSendingRegister(false);
            setName('');
            setEmail('');
            setTaxId('');
            setPassword('');
            setPasswordConfirmation('');

        })()
    }
    return(

    <div className="content-register">
    <form onSubmit={handleSubmit(handleRegister)}>
<div className="header-register">
        <h5>CADASTRE-SE</h5>
</div>
<div className="body-register">

        <h4>Para participar do bolão faça o seu cadastro.</h4>
        <div className="row">
        <div className={`form-group ${nameError ? 'not-validated' : 'was-validate'} col-12 col-sm-12`}>
            <label for="inputName">Nome Completo</label>
            <input 
            {...register("name", {
                required: "Campo Obrigatório",
                
            })}
            type="text" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} class="form-control" data-required="true" data-msg="Preencha seu nome" />
             {errors.name && <span  className="msgs-error-validate">{errors.name.message}</span> }
        </div>
        <div className={`form-group ${emailError ? 'not-validated' : 'was-validate'} col-12`}>
            <label for="inputMail2">E-mail</label>
            <input 
            {...register("email", {
                required: "Campo Obrigatório",
                pattern: {
                    value: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                    message: "Email inválido!"
                    }
                })}
            type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} class="form-control" data-required="true" data-msg="Preencha seu e-mail" />
            {errors.email && <span  className="msgs-error-validate">{errors.email.message}</span> }
        </div>

        <div  className={`form-group ${taxIdError ? 'not-validated' : 'was-validate'} col-12`}>
            <label for="inputCPF2">CPF</label>
            <InputMask 
            {...register("taxid", {
                required: "Campo Obrigatório",
                validate: (value) => {
                    value = value.replace(/[^\d]+/g,'');	
                    if(value == '') return 'CPF inválido'	
                    // Elimina values invalidos conhecidos	
                    if (value.length != 11 || 
                        value == "00000000000" || 
                        value == "11111111111" || 
                        value == "22222222222" || 
                        value == "33333333333" || 
                        value == "44444444444" || 
                        value == "55555555555" || 
                        value == "66666666666" || 
                        value == "77777777777" || 
                        value == "88888888888" || 
                        value == "99999999999")
                            return 'CPF inválido'		
                    // Valida 1o digito	
                    var add = 0;	
                    for (var i=0; i < 9; i ++)		
                        add += parseInt(value.charAt(i)) * (10 - i);	
                       var rev = 11 - (add % 11);	
                        if (rev == 10 || rev == 11)		
                            rev = 0;	
                        if (rev != parseInt(value.charAt(9)))		
                            return 'CPF inválido'		
                    // Valida 2o digito	
                    add = 0;	
                    for (i = 0; i < 10; i ++)		
                        add += parseInt(value.charAt(i)) * (11 - i);	
                    rev = 11 - (add % 11);	
                    if (rev == 10 || rev == 11)	
                        rev = 0;	
                    if (rev != parseInt(value.charAt(10)))
                        return 'CPF inválido'
                    return true; 
                }
                
            })}
            className="form-control cpf" 
            mask="999.999.999-99" 
            maskChar=""
            type="text"
            id="taxid"
            name="taxid" 
            value={taxid} 
            onChange={(e) => setTaxId(e.target.value)}
            placeholder="000.000.000-00"
            
            />
            {errors.taxid && <span  className="msgs-error-validate">{errors.taxid.message}</span> }
        </div>

        <div className={`form-group ${passwordError ? 'not-validated' : 'was-validate'} field-password col-12`}>
            <label for="inputPassword2">Senha</label>
            <input
            {...register("password", {
                required: "Senha Obrigatória",
                minLength:{value:6, message:"Senha curta demais"},
                maxLength:{value:16, message:"Senha grande demais"}
            })}
            type="password" id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} class="form-control" data-required="true" data-msg="Preencha sua senha" />
            {errors.password && <span  className="msgs-error-validate">{errors.password.message}</span> }
        </div>
        <div className={`form-group ${passwordConfirmationError ? 'not-validated' : 'was-validate'} field-password col-12`}>
            <label for="inputPasswordConfirm2">Confirme a senha</label>
            <input 
        {...register("password_confirmation", {
            required: "Senha Obrigatória",
            validate: (value) => value === watch('password') || "Ops! As senhas informadas estão diferentes." 
            })}
            type="password" id="password_confirmation" name="password_confirmation" value={password_confirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} class="form-control" data-required="true" data-msg="Preencha sua senha novamente" data-repeat="senha" />
            {errors.password_confirmation && <span className="msgs-error-validate">{errors.password_confirmation.message}</span> }
        </div>
        </div>
</div>
<div className="footer-register">
    <button type="button" class="btn-std" data-dismiss="modal" onClick={onCloseRegisterModal} >Fechar</button>
    <button type="submit" class="btn-std purple">
        {
            isSendingRegister ?
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            :
            <span>ENVIAR</span>

        }
    </button>
    <input type="hidden" name="table" value="1"></input>
</div>
    </form>
</div>
    )
}