import './footer.css';
import squareFooter from '../../assets/images/squares/quadrados-baixo.png'
import logoAtelieFooter from '../../assets/images/logos/logo-atelie.png'
import ModalRegulamento from 'react-modal';
import ModalRegulamentoBody from '../modal-regulamento/modal-regulamento';
import { useEffect, useState } from 'react';
export function Footer(){
    const [url, setUrl] = useState('');
    const [regulamentoOpen, setRegulamentoOpen] = useState(false);
    let subtitle;

    useEffect(()=> {
        //get url params to change logo
        const getUrl = window.location.pathname;

        setUrl(getUrl);
        
    }, [])

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
      }


    const customStylesRegulamento = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          margin:0,
          padding:0,
          transform: 'translate(-50%, -50%)',
          overFlow: 'auto',
          

        },
      };

    function closeModal() {
        setRegulamentoOpen(false);
      }

    function openModal(){
        setRegulamentoOpen(true);
    }
    return(
        <footer class="main-footer">
        <div class="square-footer">
        <img src={squareFooter} alt="Ateliê de Propaganda" />
        </div>
        <div class="modal-cases"></div>
        <div class="container">
            <div class="row align-content-between col-xl-12 nomarg nopad">
                <div class="box-nav nopad col-md-12 col-lg-12">
                    <div class="row">
    
                    <ul class="nav navbar-pages">
                        <li class="nav-item">
                            <a class={`nav-link ${
                                url == '/' ? 'active' : ''
                            }`} href="/">Home</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link button-toggle " href="/#mecanica" data-dismiss="modal" aria-label="Close">O bolão</a>
                        </li>
                        <li class="nav-item">
                            <a class={`nav-link ${
                                url == '/jogar' ? 'active' : ''
                            }`} href="/jogar">Jogue Agora</a>
                        </li>
                        <li class="nav-item">
                            <a class={`nav-link ${
                                url == '/estatisticas' ? 'active' : ''
                            }`
                            } href="/estatisticas">Estatísticas</a>
                        </li>
                        <li class="nav-item">
                            <a class={`nav-link ${
                                url == '/ranking' ? 'active' : ''
                            }`
                            } href="/ranking">Ranking</a>
                        </li>
                        <li class="nav-item">
                                            <a href="javascript:void(0);" class="nav-link nav-regulation" onClick={openModal}>Regulamento</a>
                        </li>
                    </ul>
                    </div>
                    <div class="row">
    
                        <div class="navbar-text col-md-6 col-lg-8 footer-texto">
                            <p>Em caso de dúvida escreva para </p>
                            <p class="atendimento-footer"><span>atendimento@palpiteepropaganda.com.br</span></p>
                            <p>Campanha válida de 21/11/2022 a 18/12/2022. Imagens meramente ilustrativas.</p>
                        </div>
                        <div class="box-image col-auto col-md-3 col-lg-2">
                           
                        </div>
                        <div class="box-image col-auto col-md-3 col-lg-2">
                            <img src={logoAtelieFooter} class="logos-footer logo-2-footer" alt="Ateliê de Propaganda" />
                        </div>
                    </div>
                    </div>
            </div>
        </div>
        <ModalRegulamento 
                        isOpen={regulamentoOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStylesRegulamento}
                        contentLabel="Registrar"
        >
            <ModalRegulamentoBody closeModalRegulamento={closeModal}/>
        </ModalRegulamento>
    </footer>
    )
}

export default Footer