import './slider.css';
import OwlCarousel from 'react-owl-carousel2'

import 'react-owl-carousel2/lib/styles.css'
export function SlideFavoritas(){
    
    const optionsMobile = {
        items: 2,
        nav: false,
        rewind: true,
        autoplay: false,
        dots: false,
        marginRight: 100,


        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 1,
            },
            1000: {
                items: 1,
    
            },
            1400: {
                items: 2,
            }
        },


    };

    return(
        
        <section className="sec-favorites">
        <div className="square-favorites">
            <img src="https://resources-palpiteepropaganda.promosatelie.com.br/images/frontend/squares/quadrados-cima.png" alt="Ateliê de Propaganda" />
        </div>
        <div className="container">
            <div className="row justify-content-md-center">
                <div className="col-12 col-lg-10">
                    <div className="row">
                        <div className="box-title col-12">
                            <h2>AS GRANDES FAVORITAS PARA LEVAR A COPA</h2>
                            <p>Segundo pesquisa, as seleções abaixo são as favoritas para levar a Copa do Mundo da Catar 2022. Entre elas está o nosso Brasil.
                                Confira algumas estatísticas que podem te ajudar no momento de escolher um placar.</p>
                            <div className="icon-hand text-center">
                                <div className="load-wrapp">
                                    <div className="load-icon">
                                        <div className="hand"></div>
                                    </div>
                                </div>
                                <p>Arraste para mover</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid">
            <div className="row justify-content-md-center corousel-section">
                <OwlCarousel options={optionsMobile}>
                <div className="item">
                        <div className="box-favorite">
                            <ul class="list-flag two-color">
                                <li class="color green"></li>
                                <li class="color yellow"></li>
                            </ul>
                        </div>
                        <div className="content">
                            <div className="box-title col-auto">
                                <h2>Seleção do<span>Brasil</span></h2>
                            </div>
                            <div className="box-info col-auto">
                                <h3>22ª participação</h3>
                                <p><strong>Melhor resultado:</strong><br />Campeã (1958, 1962, 1970, 1994 e 2002)</p>
                                <p><strong>Última participação e resultado:</strong><br />2018 (eliminada nas quartas de final)</p>
                            </div>
                        </div>
                    </div>

                <div className="item">
                        <div className="box-favorite">
                            <ul class="list-flag trhee-color">
                                <li class="color black"></li>
                                <li class="color red"></li>
                                <li class="color yellow"></li>
                            </ul>
                        </div>
                        <div className="content">
                            <div className="box-title col-auto">
                                <h2>Seleção da<span>Alemanha</span></h2>
                            </div>
                            <div className="box-info col-auto">
                                <h3>20ª participação</h3>
                                <p><strong>Melhor resultado:</strong><br />Campeã (1954, 1974, 1990 e 2014)</p>
                                <p><strong>Última participação e resultado:</strong><br />2018 (eliminada na primeira fase)</p>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="box-favorite">
                            <ul class="list-flag trhee-color">
                                <li class="color blue-dark"></li>
                                <li class="color white"></li>
                                <li class="color pink"></li>
                            </ul>
                        </div>
                        <div className="content">
                            <div className="box-title col-auto">
                                <h2>Seleção da<span>França</span></h2>
                            </div>
                            <div className="box-info col-auto">
                                <h3>16ª participação</h3>
                                <p><strong>Melhor resultado:</strong><br />Campeã (1998)</p>
                                <p><strong>Última participação e resultado:</strong><br />2018 (campeã)</p>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="box-favorite">
                            <ul class="list-flag two-color">
                                <li class="color blue"></li>
                                <li class="color white"></li>
                            </ul>
                        </div>
                        <div className="content">
                            <div className="box-title col-auto">
                                <h2>Seleção da<span>Argentina</span></h2>
                            </div>
                            <div className="box-info col-auto">
                                <h3>18ª participação</h3>
                                <p><strong>Melhor resultado:</strong><br />Campeã (1978 e 1986)</p>
                                <p><strong>Última participação e resultado:</strong><br />2018 (eliminada na primeira fase)</p>
                            </div>
                        </div>
                    </div>

                    <div className="item">
                        <div className="box-favorite">
                            <ul class="list-flag two-color">
                                <li class="color green"></li>
                                <li class="color red"></li>
                            </ul>
                        </div>
                        <div className="content">
                            <div className="box-title col-auto">
                                <h2>Seleção de<span>Portugal</span></h2>
                            </div>
                            <div className="box-info col-auto">
                                <h3>8ª participação</h3>
                                <p><strong>Melhor resultado:</strong><br />3° lugar (1966)</p>
                                <p><strong>Última participação e resultado:</strong><br />2018 (eliminada na primeira fase)</p>
                            </div>
                        </div>
                    </div>

                    <div className="item">
                        <div className="box-favorite">
                            <ul class="list-flag two-color">
                                <li class="color yellow"></li>
                                <li class="color red"></li>
                            </ul>
                        </div>
                        <div className="content">
                            <div className="box-title col-auto">
                                <h2>Seleção da<span>Espanha</span></h2>
                            </div>
                            <div className="box-info col-auto">
                                <h3>16ª participação</h3>
                                <p><strong>Melhor resultado:</strong><br />Campeã (2010)</p>
                                <p><strong>Última participação e resultado:</strong><br />2018 (eliminada na primeira fase)</p>
                            </div>
                        </div>
                    </div>
                </OwlCarousel>

            </div>
        </div>
    </section>
    )
}
export default SlideFavoritas;