import React, { Component } from 'react';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom'

import Home from './pages/home/home'
import Jogar from './pages/jogar/jogar'
import Login from './pages/login/login'
import Estatisticas from './pages/estatisticas/estatisticas';
import Ranking from './pages/ranking/ranking';
import RecoveryPassword from './pages/password-recovery/password-recovery';

import {isAuthenticated} from './services/auth'

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
          // <Component {...props} />
        )
      }
    />
  );

const PublicRoute = ({ component: Component, ...rest }) => (
<Route
    {...rest}
    render={props =>
    isAuthenticated() ? (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
    ) : (
        <Component {...props} />
    )
    }
/>

);

export default function Routes(){
    return(
        <BrowserRouter>
            <Switch>
                <PublicRoute path="/login" exact component={Login}/>
                <PublicRoute path="/password-recovery/:id" exact component={RecoveryPassword}/>
                <PrivateRoute path="/" exact component={Home}/>
                <PrivateRoute path="/jogar" component={Jogar}/>
                <PrivateRoute path="/estatisticas" component={Estatisticas}/>
                <PrivateRoute path="/ranking" component={Ranking}/>
            </Switch>
        </BrowserRouter>
    )
}